import { Button } from '@shared/ui/buttons/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const HelpingTogether = () => {
  const {
    t,
    i18n: { resolvedLanguage, language },
  } = useTranslation();
  const buttonLink = `https://social.edopomoga.gov.ua/${
    resolvedLanguage || language
  }/cabinet/volunteer/?category=online-food&shop=fozzy_group`;

  return (
    <div className="bg-master-card-banner-fozzy-background bg-cover bg-center">
      <div className="container mx-auto lg:max-w-[1280px] py-[50px]">
        <h1 className="text-white font-light text-4xl xl:text-6xl lg:w-1/2 mb-12">
          {t('helping-together-title')}
        </h1>

        <div className="text-white text-md mb-12 sm:w-3/4 md:w-2/3 lg:w-2/3 2xl:w-1/2">
          {t('helping-together-desc')}
        </div>

        <Button
          as="link-out"
          variant="rich-blue"
          size="medium"
          className="bg-custom-2"
          href={buttonLink}
        >
          {t('support-button')}
        </Button>
      </div>
    </div>
  );
};
