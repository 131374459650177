import React from 'react';

import { HelpingTogether } from '@widgets/events/mastercard/HelpingTogether';
import { HelpingTogetherBonuses } from '@widgets/events/mastercard/HelpingTogetherBonuses';
import { HowToHelpWithFozzy } from '@widgets/events/mastercard/HowToHelpWithFozzy';
import { LetsHelpTogether } from '@widgets/events/mastercard/LetsHelpTogether';
import { WhereToUse } from '@widgets/events/mastercard/WhereToUse';

const MastercardEventPage = () => {
  return (
    <div>
      <HelpingTogether />
      <HelpingTogetherBonuses />
      <WhereToUse />
      <HowToHelpWithFozzy />
      <LetsHelpTogether />
    </div>
  );
};

export default MastercardEventPage;
