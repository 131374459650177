import { GenericLink } from '@shared/ui/GenericLink';
import { Button } from '@shared/ui/buttons/Button';
import { Text } from '@shared/ui/text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRules } from './useRules';

const textClassName = 'text-3xl md:text-4xl lg:text-5xl block';

export const LetsHelpTogether = () => {
  const {
    t,
    i18n: { resolvedLanguage, language },
  } = useTranslation();

  const { rulesDocLink } = useRules();

  const linkUrl = `https://social.edopomoga.gov.ua/${
    resolvedLanguage || language
  }/cabinet/volunteer/?category=online-food&shop=fozzy_group`;

  return (
    <div className="container mx-auto flex flex-col items-center lg:max-w-[1280px] mb-3.125 md:mb-5.875">
      <div className="mb-20">
        <Text component="span" align="center" className={textClassName}>
          {t('lets-help-together-title-line-1')}
        </Text>
        <GenericLink
          className="underline underline-offset-8"
          type="outer"
          to={linkUrl}
        >
          <Text component="span" align="center" className={textClassName}>
            {t('lets-help-together-title-line-2')}
          </Text>
        </GenericLink>
      </div>

      <Button as="link-out" href={rulesDocLink} size="small">
        {t('lets-help-together-button')}
      </Button>
    </div>
  );
};
