import fora from '@assets/images/stores/fora.svg';
import fozzy from '@assets/images/stores/fozzy.svg';
import lesilpo from '@assets/images/stores/lesilpo.svg';
import silpo from '@assets/images/stores/silpo.svg';
import thrash from '@assets/images/stores/thrash.svg';
import { Header } from '@shared/ui/headers';
import React from 'react';
import { useTranslation } from 'react-i18next';

const stores = [
  {
    src: silpo,
    alt: 'Silpo',
  },
  {
    src: lesilpo,
    alt: 'LeSilpo',
  },
  {
    src: fora,
    alt: 'Fora',
  },
  {
    src: thrash,
    alt: 'Thrash',
  },
  {
    src: fozzy,
    alt: 'Fozzy',
  },
];

export const WhereToUse = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto lg:max-w-[1280px] pb-[50px] xl:pb-[100px]">
      <Header
        text={t('where-to-use-title')}
        className="text-3xl lg:text-5xl mb-10 lg:mb-20"
      />

      <div className="text-md mb-10 lg:mb-20">{t('where-to-use-desc')}</div>

      <div className="grid gap-[74px] md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5">
        {stores.map(({ src, alt }, index) => (
          <div key={index} className="flex items-center justify-center">
            <img src={src} alt={alt} />
          </div>
        ))}
      </div>
    </div>
  );
};
