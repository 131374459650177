import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface MastercardAmountBonusProps {
  amount: number;
  bonus: number;
  className?: string;
}

export const MastercardAmountBonus: React.FC<MastercardAmountBonusProps> = ({
  amount,
  bonus,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'w-full px-6 pt-[29px] pb-[23px] flex items-center justify-center bg-white border-2 border-hawkes-blue rounded-xl',
        className
      )}
    >
      <span className="flex flex-col items-center">
        <span className="font-bold text-5xl leading-none">{amount}</span>
        <span className="font-bold text-base leading-none">{t('uah')}</span>
      </span>
      <span className="flex flex-col items-center">
        <span className="font-bold text-5xl leading-none">+{bonus}</span>
        <span className="font-bold text-base leading-none">{t('uah')}</span>
      </span>
    </div>
  );
};
