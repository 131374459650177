import clsx from 'clsx';
import React from 'react';

import {
  getBackgroundColor,
  getBeforeBackgroundColor,
  getBorderColor,
} from '@shared/styles';
import { Color } from '@shared/types/common.types';

interface MastercardStepBlockProps {
  position: number;
  children: React.ReactElement | string;
  color: Color;
  secondaryColor: Color;
  whiteBg?: boolean;
}

export const MastercardStepBlock: React.FC<MastercardStepBlockProps> = ({
  position,
  secondaryColor,
  children,
  color,
  whiteBg = false,
}) => {
  return (
    <div
      data-position={position}
      className={clsx(
        'flex flex-col min-h-[276px] pt-4.875 pb-[33px] px-[40px] rounded-xl relative',
        'before:text-white before:left-1/2 before:top-0 before:rounded-full before:absolute before:w-4.875 before:h-4.875 md:before:w-[108px] md:before:h-[108px] before:-translate-y-2/4 before:-translate-x-2/4 before:content-[attr(data-position)] before:text-3xl md:before:text-5xl before:text-center before:leading-[4.875rem] md:before:leading-[108px]',
        !whiteBg && getBackgroundColor(color),
        getBeforeBackgroundColor(secondaryColor),
        getBorderColor(color),
        getBorderColor(color) && 'border-[3px]'
      )}
    >
      {children}
    </div>
  );
};
