import { Color, Icon } from '@shared/types/common.types';

interface ColorStyle {
  backgroundColor: string;
  beforeBackgroundColor: string;
  color: string;
  borderColor?: string;
}

interface IconStyle {
  beforeBackgroundImage?: string;
}

const colors: Record<Color, ColorStyle> = {
  black: {
    backgroundColor: 'bg-black',
    color: 'text-black',
    beforeBackgroundColor: 'before:bg-black',
  },
  white: {
    backgroundColor: 'bg-white',
    color: 'text-white',
    beforeBackgroundColor: 'before:bg-white',
  },
  solitude: {
    backgroundColor: 'bg-solitude',
    color: 'text-solitude',
    beforeBackgroundColor: 'before:bg-solitude',
  },
  beige: {
    backgroundColor: 'bg-beige',
    color: 'text-beige',
    beforeBackgroundColor: 'before:bg-beige',
  },
  gray: {
    backgroundColor: 'bg-gray',
    color: 'text-gray',
    beforeBackgroundColor: 'before:bg-gray',
  },
  'rich-blue': {
    backgroundColor: 'bg-rich-blue',
    color: 'text-rich-blue',
    beforeBackgroundColor: 'before:bg-rich-blue',
  },
  'hawkes-blue': {
    backgroundColor: 'bg-hawkes-blue',
    color: 'text-hawkes-blue',
    beforeBackgroundColor: 'before:bg-hawkes-blue',
  },
  'alice-blue': {
    backgroundColor: 'bg-alice-blue',
    color: 'text-alice-blue',
    beforeBackgroundColor: 'before:bg-alice-blue',
  },
  'granny-apple': {
    backgroundColor: 'bg-granny-apple',
    color: 'text-granny-apple',
    beforeBackgroundColor: 'before:bg-granny-apple',
  },
  gainsboro: {
    backgroundColor: 'bg-gainsboro',
    color: 'text-gainsboro',
    beforeBackgroundColor: 'before:bg-gainsboro',
  },
  shamrock: {
    backgroundColor: 'bg-shamrock',
    color: 'text-shamrock',
    beforeBackgroundColor: 'before:bg-shamrock',
  },
  dew: {
    backgroundColor: 'bg-dew',
    color: 'text-dew',
    beforeBackgroundColor: 'before:bg-dew',
  },
  fuego: {
    backgroundColor: 'bg-fuego',
    color: 'text-fuego',
    beforeBackgroundColor: 'before:bg-fuego',
  },
  frost: {
    backgroundColor: 'bg-frost',
    color: 'text-frost',
    beforeBackgroundColor: 'before:bg-frost',
  },
  carnation: {
    backgroundColor: 'bg-carnation',
    color: 'text-carnation',
    beforeBackgroundColor: 'before:bg-carnation',
  },
  'light-yellow': {
    backgroundColor: 'bg-light-yellow',
    color: 'text-light-yellow',
    beforeBackgroundColor: 'before:bg-light-yellow',
  },
  'dark-yellow': {
    backgroundColor: 'bg-dark-yellow',
    color: 'text-dark-yellow',
    beforeBackgroundColor: 'before:bg-dark-yellow',
  },
  'light-green': {
    backgroundColor: 'bg-light-green',
    color: 'text-light-green',
    beforeBackgroundColor: 'before:bg-light-green',
  },
  'dark-green': {
    backgroundColor: 'bg-dark-green',
    color: 'text-dark-green',
    beforeBackgroundColor: 'before:bg-dark-green',
  },
  'darker-green': {
    backgroundColor: 'bg-darker-green',
    color: 'text-darker-green',
    beforeBackgroundColor: 'before:bg-darker-green',
  },
  'black-green': {
    backgroundColor: 'bg-black-green',
    color: 'text-black-green',
    beforeBackgroundColor: 'before:bg-black-green',
  },
  'custom-2': {
    backgroundColor: 'bg-custom-2',
    color: 'text-custom-2',
    beforeBackgroundColor: 'before:bg-custom-2',
    borderColor: 'border-custom-2',
  },
  'custom-1': {
    backgroundColor: 'bg-custom-1',
    color: 'text-custom-1',
    beforeBackgroundColor: 'before:bg-custom-1',
    borderColor: 'border-custom-1',
  },
  'mastercard-yellow': {
    backgroundColor: 'bg-mastercard-yellow',
    color: 'text-mastercard-yellow',
    beforeBackgroundColor: 'before:bg-mastercard-yellow',
    borderColor: 'border-mastercard-yellow',
  },
  'light-red': {
    backgroundColor: 'bg-light-red',
    color: 'text-light-red',
    beforeBackgroundColor: 'before:bg-light-red',
    borderColor: 'border-light-red',
  },
};

const icons: Record<Icon, IconStyle> = {
  checkbox: {
    beforeBackgroundImage: 'before:bg-checkbox',
  },
  'credit-card': {
    beforeBackgroundImage: 'before:bg-credit-card',
  },
  'get-help-block': {
    beforeBackgroundImage: 'before:bg-get-help-block',
  },
  'help-hand-block': {
    beforeBackgroundImage: 'before:bg-help-hand-block',
  },
  'help-online-block': {
    beforeBackgroundImage: 'before:bg-help-online-block',
  },
  'help-online-block-sm': {
    beforeBackgroundImage: 'before:bg-help-online-block-sm',
  },
  'help-online-block-216px': {
    beforeBackgroundImage: 'before:bg-help-online-block-216px',
  },
  'shopping-bag': {
    beforeBackgroundImage: 'before:bg-shopping-bag',
  },
  'shopping-cart': {
    beforeBackgroundImage: 'before:bg-shopping-cart',
  },
  camera: {
    beforeBackgroundImage: 'before:bg-camera',
  },
  'folded-hands': {
    beforeBackgroundImage: 'before:bg-folded-hands',
  },
  'thinking-face': {
    beforeBackgroundImage: 'before:bg-thinking-face',
  },
  'arrow-down': {},
  handshake: {
    beforeBackgroundImage: 'before:bg-handshake',
  },
  'handshake-small': {
    beforeBackgroundImage: 'before:bg-handshake-small',
  },
  'camera-handshake': {
    beforeBackgroundImage: 'before:bg-camera-handshake',
  },
  'light-bulb': {
    beforeBackgroundImage: 'before:bg-light-bulb',
  },
  'together-customer-volunteer': {
    beforeBackgroundImage: 'before:bg-together-customer-volunteer',
  },
  'together-customer-volunteer-sm': {
    beforeBackgroundImage: 'before:bg-together-customer-volunteer-sm',
  },
  'happy-customer': {
    beforeBackgroundImage: 'before:bg-happy-customer',
  },
  'happy-customer-sm': {
    beforeBackgroundImage: 'before:bg-happy-customer-sm',
  },
  'sign-of-the-horns': {
    beforeBackgroundImage: 'before:bg-sign-of-the-horns',
  },
  'about-us': {
    beforeBackgroundImage: 'before:bg-about-us',
  },
  'point-up': {
    beforeBackgroundImage: 'before:bg-point-up',
  },
};

export function getTextColor(color: Color) {
  return colors[color].color;
}

export function getBackgroundColor(color: Color) {
  return colors[color].backgroundColor;
}

export function getBeforeBackgroundColor(color: Color) {
  return colors[color].beforeBackgroundColor;
}

export function getBeforeBackgroundIcon(icon: Icon) {
  return icons[icon]?.beforeBackgroundImage || '';
}

export function getBorderColor(color: Color) {
  return colors[color].borderColor;
}
