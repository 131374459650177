import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MastercardAmountBonus } from './AmountBonus';

const bonuses = [
  {
    amount: 200,
    bonus: 50,
    className: 'bg-mastercard-yellow !border-mastercard-yellow',
  },
  {
    amount: 500,
    bonus: 75,
    className: 'bg-custom-2 !border-custom-2',
  },
  {
    amount: 1000,
    bonus: 100,
    className: 'bg-light-red !border-light-red',
  },
];

export const HelpingTogetherBonuses = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto lg:max-w-[1280px] py-[50px] lg:py-[70px] 2xl:py-[130px]">
      <p
        className="text-md"
        dangerouslySetInnerHTML={{ __html: t('helping-together-info') }}
      />

      <div className="grid gap-[30px] xl:gap-[58px] lg:grid-cols-3 mt-[50px] xl:mt-[100px]">
        {bonuses.map(({ amount, bonus, className }, index) => (
          <MastercardAmountBonus
            key={index}
            amount={amount}
            bonus={bonus}
            className={clsx('text-white', className)}
          />
        ))}
      </div>
    </div>
  );
};
